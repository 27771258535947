"use client";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from "@chakra-ui/react";

import { useRouter } from "next/navigation";
import { Login, LoginPayload } from "@/actions/auth/Login";
import ClientIcon from "@/components/ClientIcon";
import { Link } from "@chakra-ui/next-js";
import { useForm } from "react-hook-form";
import { checkFormError } from "@/utils/helpers";
import { EVENT_LIBRARY, ROUTE_PATHS } from "@/lib/constants";
import { useState } from "react";
import useAnalytics from "@/lib/hooks/useAnalytics";
import validations from "@/utils/validations";
import handleServerAction from "@/lib/hooks/handleServerAction";

interface LoginFormProps {
  isJailUser?: boolean;
  isViaPathSso?: boolean;
}

const LoginForm = ({ isJailUser, isViaPathSso }: LoginFormProps) => {
  const toast = useToast();
  const track = useAnalytics();
  const router = useRouter();

  const [canViewPassword, setCanViewPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginPayload>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (payload: LoginPayload) => {
    try {
      await handleServerAction(Login, payload);
      track(EVENT_LIBRARY.LOGGED_IN);
      if (isJailUser) {
        if (isViaPathSso) {
          router.push(ROUTE_PATHS.DASHBOARD.HOME);
        } else {
          router.push(
            ROUTE_PATHS.TABLET.PASSWORD_RESET_SECURITY_QUESTION.CREATE
          );
        }
      } else {
        router.push(ROUTE_PATHS.DASHBOARD.HOME);
      }
    } catch (err) {
      toast({
        status: "error",
        title: "Login Failed",
        description: err.message,
      });
    }
  };

  return (
    <Box w="100%" as="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="24px" w="100%">
        <FormControl isInvalid={errors.email ? true : false}>
          <Input
            id="email"
            type={isJailUser ? "text" : "email"}
            placeholder={
              isJailUser ? "Username/Email Address" : "Email Address"
            }
            variant="auth"
            {...register("email", {
              required: `${
                isJailUser ? "Username/" : ""
              }Email Address is required`,
              pattern: isJailUser ? undefined : validations.email,
            })}
          />
          <FormErrorMessage>{checkFormError(errors, "email")}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password ? true : false}>
          <InputGroup>
            <Input
              id="password"
              type={canViewPassword ? "text" : "password"}
              placeholder="Password"
              variant="auth"
              {...register("password", {
                required: "Password is required.",
              })}
            />
            <InputRightElement
              height="68px"
              pr="24px"
              cursor="pointer"
              onClick={() => setCanViewPassword(!canViewPassword)}
            >
              <ClientIcon
                icon={canViewPassword ? "FaEye" : "FaEyeSlash"}
                color="#8EA4AC"
                size="18px"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {checkFormError(errors, "password")}
          </FormErrorMessage>
        </FormControl>
        <Button
          variant="primary"
          size="lg"
          type="submit"
          borderRadius="8px"
          py="15px"
          px="28px"
          fontSize="18px"
          lineHeight="24px"
          fontWeight="700"
          w="100%"
          h="54px"
          isLoading={isSubmitting}
        >
          Sign In Securely
        </Button>
        <HStack justifyContent="space-between">
          <Link
            href={ROUTE_PATHS.PREQUALIFY}
            fontSize="16px"
            fontWeight="500"
            color="#8EA4AC"
          >
            Need an account?
          </Link>
          <Link
            href={
              isJailUser
                ? ROUTE_PATHS.TABLET.PASSWORD_RESET_SECURITY_QUESTION.ANSWER
                : ROUTE_PATHS.FORGOT_PASSWORD
            }
            variant="link"
            fontSize="16px"
            fontWeight="500"
            color="#8EA4AC"
          >
            Forgot Password?
          </Link>
        </HStack>
      </Stack>
    </Box>
  );
};

export default LoginForm;
